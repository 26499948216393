<template>
  <font-awesome-icon @click="click" :size="size" :icon="resolve(icon)" :beat-fade="beat_fade"
                     :fade="fade"></font-awesome-icon>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

export default {
  name: 'netvs-icon',
  components: {
    'font-awesome-icon': FontAwesomeIcon
  },
  data() {
    return {
      map: {
        offline: 'fa-solid fa-link-slash',
        home: 'fa-solid fa-home',
        dnsvs: 'fa-solid fa-signs-post',
        record: 'fa-solid fa-signs-post',
        fqdn: 'fa-solid fa-signs-post',
        macauth: 'fa-solid fa-lock',
        bcd_seclvl: 'fa-solid fa-shield-halved',
        bcd_categ: 'fa-regular fa-layer-group',
        dhcp_leases: 'fa-solid fa-list-ol',
        natvs: 'fa-solid fa-block-brick-fire',
        netdoc: 'fa-solid fa-network-wired',
        orgs: 'fa-solid fa-building',
        org_types: 'fa-regular fa-building',
        macfinder: 'fa-solid fa-magnifying-glass-location',
        groups: 'fa-solid fa-users',
        macvendor_search: 'fa-solid fa-industry-windows',
        ap_list: 'fa-solid fa-tower-broadcast',
        requests: 'fa-solid fa-scroll',
        copy: 'fa-solid fa-copy',
        collapse: 'fa-solid fa-chevron-down',
        delete: 'fa-regular fa-trash-can',
        create: 'fa-solid fa-plus',
        export: 'fa-solid fa-file-export',
        import: 'fa-solid fa-file-import',
        edit: 'fa-regular fa-pen-to-square',
        search: 'fa-solid fa-magnifying-glass',
        evlog: 'fa-solid fa-clock-rotate-left',
        filter: 'fa-solid fa-filter',
        execute: 'fa-solid fa-paper-plane',
        move_up: 'fa-solid fa-caret-up',
        move_down: 'fa-solid fa-caret-down',
        settings: 'fa-solid fa-gear',
        devel_warning: 'fa-solid fa-dumpster-fire',
        test: 'fa-solid fa-vial',
        prod: 'fa-regular fa-conveyor-belt-arm',
        help: 'fa-solid fa-question',
        unknown: 'fa-solid fa-question',
        navigate: 'fa-solid fa-arrow-right',
        language: 'fa-solid fa-language',
        link: 'fa-solid fa-link',
        vlan: 'fa-solid fa-ethernet',
        vni: 'fa-solid fa-ethernet',
        cntl: 'fa-solid fa-user',
        transaction_list: 'fa-regular fa-arrow-progress',
        delete_transaction_list: 'fa-regular fa-trash-list',
        delete_transaction_element: 'fa-regular fa-delete-left',
        clear: 'fa-solid fa-xmark',
        token: 'fa-solid fa-key',
        subgroups: 'fa-solid fa-users-between-lines',
        subaccounts: 'fa-solid fa-users',
        unlink: 'fa-solid fa-link-slash',
        bcd: 'fa-solid fa-network-wired',
        group_members: 'fa-solid fa-users',
        info: 'fa-solid fa-circle-info',
        mail: 'fa-solid fa-envelope',
        undo: 'fa-solid fa-arrow-rotate-left',
        redo: 'fa-solid fa-arrow-rotate-right',
        expert_feature: 'fa-light fa-rectangle-pro',
        sign_in: 'fa-solid fa-arrow-right-to-bracket',
        sign_in_oidc: 'fa-brands fa-openid',
        sign_out: 'fa-solid fa-arrow-right-from-bracket',
        documentation_help: 'fa-solid fa-life-ring',
        api: 'fa-solid fa-code',
        bug: 'fa-solid fa-bug',
        developer_feature: 'fa-solid fa-bug',
        development_feature: 'fa-solid fa-bug',
        mobile_menu_sandwich: 'fa-solid fa-bars',
        user: 'fa-solid fa-user',
        user_ninja: 'fa-solid fa-user-ninja',
        sidebar_collapse_to_left: 'fa solid fa-angles-left',
        sidebar_expand_to_left: 'fa solid fa-angles-left',
        sidebar_collapse_to_right: 'fa solid fa-angles-right',
        sidebar_expand_to_right: 'fa solid fa-angles-right',
        transaction_error: 'fa-solid fa-triangle-exclamation',
        transaction_success: 'fa-solid fa-circle-check',
        request_sent: 'fa-regular fa-envelope-circle-check',
        netvs_fatal_error: 'fa-solid fa-poo-storm',
        update_new_version: 'fa-solid fa-arrows-rotate',
        launchpad: 'fa-solid fa-rocket',
        launchpad_active: 'fa-solid fa-rocket-launch',
        launchpad_planet: 'fa-solid fa-planet-ringed',
        navigation_list_marker: 'fa-solid fa-chevron-left',
        power: 'fa-solid fa-bolt',
        monitoring_up: 'fa-solid fa-check',
        monitoring_down: 'fa-solid fa-xmark',
        refire_job: 'fa-solid fa-arrows-rotate',
        warning: 'fa-solid fa-triangle-exclamation',
        external_hazard: 'fa-solid fa-radiation',
        transaction_returning: 'fa-solid fa-left-long-to-line',
        community_content: 'fa-solid fa-star-shooting',
        garbage_collector: 'fa-solid fa-toilet',
        alerts: 'fa-solid fa-bell',
        all_good: 'fa-regular fa-face-smile',
        add_item: 'fa-light fa-square-plus',
        ip_tools: 'fa-solid fa-toolbox',
        eye_slash: 'fa-solid fa-eye-slash',
        eye: 'fa-solid fa-eye',
        transaction_bundle: 'fa-regular fa-wand-magic-sparkles',
        transaction_additional: 'fa-regular fa-wand-magic-sparkles',
        transaction_api_object: 'fa-solid fa-code-simple',
        close: 'fa-solid fa-xmark',
        depersonate: 'fa-regular fa-portal-exit',
        impersonate: 'fa-regular fa-portal-enter',
        svc_account: 'fa-user-robot',
        user_locked: 'fa-regular fa-user-lock',
        check: 'fa-solid fa-check',
        external_link: 'fa-solid fa-external-link',
        grid: 'fa-solid fa-grid-2',
        list: 'fa-solid fa-list',
        list_tree: 'fa-solid fa-list-tree',
        arrow_down_from_line: 'fa-solid fa-arrow-down-from-line',
        arrow_up_from_line: 'fa-solid fa-arrow-up-from-line',
        ip_contact: 'fa-regular fa-typewriter',
        domain_finder: 'fa-regular fa-globe-pointer',
        graph: 'fa-regular fa-circle-nodes',
        building: 'fa-solid fa-house',
        room: 'fa-solid fa-door-open',
        active_module: 'fa-solid fa-server',
        device: 'fa-solid fa-server',
        dd_module: 'fa-solid fa-outlet',
        target_module: 'fa-solid fa-arrow-right-to-arc',
        chat: 'fa-solid fa-comment',
        maximize: 'fa-solid fa-maximize',
        minimize: 'fa-solid fa-minimize',
        geo_address: 'fa-solid fa-map-location-dot',
        geo_marker: 'fa-solid fa-location-dot',
        defective: 'fa-regular fa-skull',
        wire: 'fa-solid fa-hose',
        group_join: 'fa-solid fa-user-plus',
      }
    }
  },
  methods: {
    resolve(name) {
      if (name in this.map) {
        return this.map[name]
      }
      window.console.warn(`Unknown netvs-icon: ${name}`)
      return 'fa-regular fa-face-sad-sweat'
    },
    click(e) {
      this.$emit('click', e)
    }
  },
  props: {
    size: {
      type: String,
      default() {
        return null
      }
    },
    icon: {
      required: true,
      default() {
        return 'fa-regular fa-face-sad-sweat'
      }
    },
    beat_fade: {
      required: false,
      default() {
        return false
      }
    },
    fade: {
      required: false,
      default() {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
